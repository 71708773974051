import React from 'react'
import { graphql } from 'gatsby'
import ReactMarkdown from 'react-markdown'
import Moment from 'react-moment'
import Layout from '../components/layout'

export const query = graphql`
  query DownloadQuery($id: Int!) {
    strapiDownload(strapiId: { eq: $id }) {
      strapiId
      name
      description
      content {
        publicURL
      }
    }
  }
`

const Download = ({ data }) => {
  const download = data.strapiDownload
  return (
    <Layout>
      <div>
          <h1>{download.name}</h1>

        <div className="uk-section">
          <div className="uk-container uk-container-small">
            asdf {download.description}
            <ReactMarkdown source={download.description} />
            <p>
              <Moment format="MMM Do YYYY">{download.published_at}</Moment>
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Download
